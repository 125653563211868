@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    --scrollbarBG: rgb(227, 226, 226);
    --thumbBG: #041122;
    --primary:#e22128;
    --secondary:#041122;
    --white:#ffffff;
    --darkBlue:#0e3454;
  }

body{
    margin: 0;
    padding: 0;
    font-family: 'Outfit', sans-serif !important;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    background: #ffffff !important;
}

body::-webkit-scrollbar {
    width: 10px;
  }
  
body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  a {
    text-decoration: none !important;
  }

  /* -------- Buttons ----------*/
    .btn-login{
      color: var(--white) !important;
      background: var(--primary) !important;
      border: none !important;
      border-radius: 10px !important;
      display: flex !important;
      justify-content:  center !important;
      align-items:  center !important;
      width: max-content !important;
    }

    .btn-cancel{
      color: var(--primary) !important;
      background: transparent !important;
      border: 1px solid #ef8941!important;
      border-radius: 10px !important;
      display: flex !important;
      justify-content:  center !important;
      align-items:  center !important;
      padding: 5px 11px !important;
    }

    .modal .modal-content {
      border: none !important;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .about #mission{
      scroll-margin-top:80px !important;
    }

  /* ------------ Navbar CSS ------------------ */
  
  .navbarTransperent{
    background-color: rgb(5,19,35,0.9);
    transition: 0.3s ease-in-out;
}
  
.navbarBlack{
  background-color: rgb(5,19,35,0.9);
    transition: 0.3s ease-in-out;
}
  nav{
    position: fixed;
    width:100%;
    /* background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    z-index: 1000;
   }
  
   nav ul{
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;
}
  
   nav ul li {
    letter-spacing: 1px;
    font-weight: 500;
    padding: 2px 10px;
    transition: 0.2s;
    display: inline-block;
    color: var(--white);
  }
  
  nav .mobile-nav li{
    font-size: 30px !important;
  }

  nav ul li:hover{
    transform: translateY(-3px);
    color: var(--primary);
   }
    
  @media(max-width:992px){
    
  .lg-nav{
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap:2rem;
    border-radius: 0px;
    inset:0 0 0 0%;
    z-index: 1000;
    flex-direction: column;
    /* padding: min(20vh, 10rem) 2em; */
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background:var(--darkBlue);
    }
    
    .mobile-nav li {
      margin-bottom: 15px;
      color: var(--white);
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .mobile-nav[data-visible="true"]{
      transform: translateX(0%);
     }

   .mobile-nav-toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all .5s ease-in-out;

    /* border: 3px solid #fff; */
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: var(--white);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
    z-index: 9999;
    
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 36px;
    height: 3px;
    background: var(--white);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
   
  }
  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }
  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
   
  }
  
  @media(min-width:992px){
  .lg-nav{
    display: flex;
  }
  .mobile-nav{
    display: none;
  }
  .mobile-nav-toggle{
    display: none;
  }
  }
 


  /* ----- Home Banner Carousel CSS ------ */
    .banner,
    .mobile-banner{
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100vh;
    }

/*
.banner h1{
  font-family: 'Anton', sans-serif;
font-style: italic;
  font-size: 80px;
  color: var(--yellow-brand-color) !important;
  letter-spacing: 2px;
  margin:0px;
  text-shadow:  1px 2px black,
                3px 3px white,
                2px 2px white;
   -webkit-transition: all 0.12s ease-out;
   -moz-transition:    all 0.12s ease-out;
   -ms-transition:     all 0.12s ease-out;
   -o-transition:      all 0.12s ease-out;
}

.mobile-banner h1{
  font-family: 'Anton', sans-serif;
  font-style: italic;
  font-size: 80px;
  color: black !important;
  letter-spacing: 2px;
  margin:0px;
  text-shadow:  1px 2px var(--yellow-brand-color),
                3px 3px white,
                2px 2px black;
   -webkit-transition: all 0.12s ease-out;
   -moz-transition:    all 0.12s ease-out;
   -ms-transition:     all 0.12s ease-out;
   -o-transition:      all 0.12s ease-out;
} */

.MobileModel div{
  padding: 0px 15px;
  position: absolute;
  height: 50vh;
  bottom: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
 align-items: center;
}

.MobileModel div h1{
  font-size: 60px;
}


.carousel-item.active svg {
    animation: fadeInleft 0.8s ease forwards;
  }
@keyframes fadeInleft {
  0% {
      opacity: 0;
      transform: translateY(-30px) ;
  }
  100% {
      opacity: 1;
      transform: translateY(0px) rotate(-5px);
  }
}

@media(min-width:992px){
  .home .banner{
    display: block;
  }
  .home .banner h2{
    color: var(--white);
    font-weight: 700;
    text-shadow: rgba(0, 0, 0, 0.4) 1px 0 10px;
  }
  .mobile-banner{
    display: none;
  }
}

/* -- Mobile Model ---*/
@media(max-width:992px){
.home .mobile-banner{
  display: none;
}
.home .banner h2{
  color: var(--white);
  font-weight: 700;
  text-shadow: rgba(0, 0, 0, 0.4) 1px 0 10px;
}
.home .banner{
  display: block;
}
}

/* ----------- Global Music ------------------ */
.globalMusic h1{
  font-weight: 700;
}
.globalMusic p{
  font-size: 20px;
  margin: 0px;
}
.globalMusic .para{
  border-left: 2px solid #3d3d3d;
  padding: 0px 12px 0px 15px;
}

@media(max-width:768px){
  .globalMusic .para{
    border:none;
  }

   .discover .upcoming{
    display: flex !important;
    flex-direction: row !important;
    overflow-x: scroll !important;
  }

  .discover .upcoming img{
    width: 30vh !important;
    margin-right: 20px !important;
  }
}

/* ----------- Discover Full Screen Cover CSS -------------*/


.discover{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f0f1f2;
}

.discover h2{
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--darkBlue);
}

.discover .nav{
  border: none;
  border-radius: 10px;
  /* box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
  rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}

.discover .nav .nav-link{
  border-radius: 10px;
  font-size: 21px;
  color: var(--secondary);
  padding: 6px 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discover .nav .nav-link.active{
  background: linear-gradient(145deg, #ff4f54, #e22128);
  padding: 6px 18px;
  font-size: 21px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
}

.discover img{
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.discover .card{
  border: none;
}


/* -------- Vibe CSS -----------*/

.vibe h2{
  font-weight: 700;
}

/*---------- About CSS ------------*/

@media(max-width:768px) {
  .about .banner{
    background: url('./Assets/AboutMobBanner.png') center center no-repeat !important;
    background-size: cover !important;
    height: 100vh !important;
    color: #ffffff;
    padding-top: 10vh !important;
  }
}

@media(min-width:768px) {
  .about .banner{
    background: url('./Assets/aboutBanner.jpg') center center no-repeat !important;
    background-size: cover !important;
    height: 100vh !important;
    color: #ffffff;
  }
}

.about .banner h2{
  font-weight: 700;
  color: var(--white);
}

.about .team h1{
  font-weight: 700;
  text-transform: uppercase;
  color: var(--darkBlue);
  margin-bottom: 25px;
}

.about .team h2{
  color: #424242;
  font-weight: 600;
}

.about .team p{
  font-size: 18px;
  color: #191919;
}

.about .team img{
  border-radius: 10px;
}

.about .mission h1{
  font-weight: 700;
}

.home .music h1{
  font-weight: 700;
  font-size: 50px;
  color: var(--darkBlue);
}
.home .music p{
  font-size: 20px;
}

.home .music p span{
  color: #e22128;
}
.home .music h1 span{
  color: #e22128;
}
.home .music img{
  border-radius: 10px;
}
.home .music .icons{
  display:  flex;
  justify-content: space-around;
  align-items: center;
}

.home .music .icons svg{
  color: var(--darkBlue);
}
@media(max-width:768px){
  .home .music h1{
    
    font-size: 25px;
  }
}

.digital .banner{
  background: url('./Assets/digitalBanner.jpg') center center no-repeat;
  background-size:cover;
  height: 70vh;
}

.digital .banner h1{
  font-weight: 700;
  color: var(--white);
  text-shadow: #000000;
}

.digital .distribute h1{
  color: #000000;
  font-weight: 600;
} 

.digital .distribute p{
  font-size: 20px;
}

.digital .distribute img{
  border-radius: 10px;
}

 .digital .features .card{
  border: none;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-align: center;
  padding: 20px;
 }

 .digital h1{
  font-weight: 600;
  text-align: center;
 }
 
 .digital .digiContact input{
  border-width: 0px 0px 1px 0px;
  border-color: #000000;
  border-radius: 0px;
 }

 .digital .digiContact input:focus{
  outline: none;
  box-shadow: none;
 }

/* -------- Contact Page CSS -----------*/
 .contact .banner{
  background: url('./Assets/contactBanner.jpg') center center no-repeat;
  background-size: cover;
  height: max-content;
  padding: 15vh 0px;
 }

.contact .banner .card{
  border: none;
  border-radius:10px;
  padding: 20px;

}

.contact .banner .mails a{
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 12px;
  text-align: center;
  background: #e22128;
  padding: 6px 12px;
  border-radius: 10px;
  transition: 0.3s;

}
.contact .banner .mails a:hover{
  transform: translateY(-3px) !important;
}
 .contact form input,
 .contact form textarea{
  background: transparent;
  border: 1px solid #ef8941;
  color: #ef8941;
  border-radius: 10px;
 }



/* ------ Footer CSS -------*/
footer{
  background: var(--darkBlue);
}

footer .menus{
  color: #ffffff;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  padding: 0px 30px;
}
footer .menus h6{
  font-size: 14px;
}
footer .menus h6:hover{
  cursor: pointer;
}


footer .icons{
  display:  flex;
  justify-content: space-between;
  align-items: center;
}
   
footer .icons svg{
color: #ffffff;
}

footer p{
  color: var(--white);
  font-size: 12px;
  text-align: center;
  margin: 0;
}




/* 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
